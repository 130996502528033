import React from "react";

export default class SuccessMsg extends React.Component {
  render() {
    return (
      <section className="success-msg">
        
      </section>
    );
  }
}
