//Pasta
import ChickenAlfredo from '../assets/images/catalogo/fregadero.png'
import AllaGricia from '../assets/images/catalogo/fregadero.png'
import SheetPan from '../assets/images/catalogo/fregadero.png'
import AllaVodka from '../assets/images/catalogo/fregadero.png'
import BakedSpaghetti from '../assets/images/catalogo/fregadero.png'
import PotatoGnocchi from '../assets/images/catalogo/fregadero.png'
import BakedZiti from '../assets/images/catalogo/fregadero.png'
import CacioPepe from '../assets/images/catalogo/fregadero.png'
import PastaPuttanesca from'../assets/images/catalogo/fregadero.png'
import ItalianPastaSalad from '../assets/images/catalogo/fregadero.png'
import Lasagna from '../assets/images/catalogo/fregadero.png'
import ToastedRavioli from '../assets/images/catalogo/fregadero.png'
import OrecchietteBroccoli from '../assets/images/catalogo/fregadero.png'
import LambRagu from '../assets/images/catalogo/fregadero.png'
import LasagnaBolognese from '../assets/images/catalogo/fregadero.png'
import LemonRicotta from '../assets/images/catalogo/fregadero.png'
import StuffedRigatoni from '../assets/images/catalogo/fregadero.png'
import FiveCheese from '../assets/images/catalogo/fregadero.png'
import ChickenFlorentine from '../assets/images/catalogo/fregadero.png'
import MacCheese from '../assets/images/catalogo/fregadero.png'
//Pizza
import CheesePizza from '../assets/images/catalogo/fregadero.png'
import ExtraCheesePizza from '../assets/images/catalogo/fregadero.png'
import VeggiePizza from '../assets/images/catalogo/fregadero.png'
import PepperoniPizza from '../assets/images/catalogo/fregadero.png'
import MeatPizza from '../assets/images/catalogo/fregadero.png'
import MargheritaPizza from '../assets/images/catalogo/fregadero.png'
import BBQPizza from '../assets/images/catalogo/fregadero.png'
import AllaPalla from '../assets/images/catalogo/fregadero.png'
import Prosciutto from '../assets/images/catalogo/fregadero.png'
import DetroitStyle from '../assets/images/catalogo/fregadero.png'
import GarlicFingers from '../assets/images/catalogo/fregadero.png'
import StLous from '../assets/images/catalogo/fregadero.png'
import Rustica from '../assets/images/catalogo/fregadero.png'
import Genovese from '../assets/images/catalogo/fregadero.png'
import Efichi from '../assets/images/catalogo/fregadero.png'
import Apizza from '../assets/images/catalogo/fregadero.png'
import StuffedPizza from '../assets/images/catalogo/fregadero.png'
import Pugliese from '../assets/images/catalogo/fregadero.png'
import TomatoPie from '../assets/images/catalogo/fregadero.png'
import Fugaza from '../assets/images/catalogo/fregadero.png'
import DiZucca from '../assets/images/catalogo/fregadero.png'
import Fugazeta from '../assets/images/catalogo/fregadero.png'
import Carbonara from '../assets/images/catalogo/fregadero.png'
import GranmaPie from '../assets/images/catalogo/fregadero.png'
import Padellino from '../assets/images/catalogo/fregadero.png'
import CaliforniaStyle from '../assets/images/catalogo/fregadero.png'
import Fritta from '../assets/images/catalogo/fregadero.png'
import Romana from '../assets/images/catalogo/fregadero.png'
import ThinCrust from '../assets/images/catalogo/fregadero.png'
import GreekStyle from '../assets/images/catalogo/fregadero.png'
import PizzaRomana from '../assets/images/catalogo/fregadero.png'
//Sushi
import SalmonPoke from '../assets/images/catalogo/fregadero.png'
import VeggiePoke from '../assets/images/catalogo/fregadero.png'
import MiniSalmon from '../assets/images/catalogo/fregadero.png'
import OmbosSeth from '../assets/images/catalogo/fregadero.png'
import VeggieSet from'../assets/images/catalogo/fregadero.png'
import FriendlySet from '../assets/images/catalogo/fregadero.png'
import SunnyPhiladelphia from '../assets/images/catalogo/fregadero.png'
import HiddenDragon from '../assets/images/catalogo/fregadero.png'
import HottestTaisho from '../assets/images/catalogo/fregadero.png'
import BurningTaisho from '../assets/images/catalogo/fregadero.png'
import BlissfulEel from '../assets/images/catalogo/fregadero.png'
import Sebastian from '../assets/images/catalogo/fregadero.png'
import DoubleSalmon from '../assets/images/catalogo/fregadero.png'
import ClockworkOrange from '../assets/images/catalogo/fregadero.png'
import KiwiRoll from '../assets/images/catalogo/fregadero.png'
import Siamese from '../assets/images/catalogo/fregadero.png'
import SalmonMaki from '../assets/images/catalogo/fregadero.png'
import NigiriWithRoasted from '../assets/images/catalogo/fregadero.png'
import NigiriWithSalmon from '../assets/images/catalogo/fregadero.png'
import NigiriWithEel from '../assets/images/catalogo/fregadero.png'
import VulcanTobiko from '../assets/images/catalogo/fregadero.png'
import SalmonGunkan from '../assets/images/catalogo/fregadero.png'
import VeryVegetarian from '../assets/images/catalogo/fregadero.png'
import SushiOne from '../assets/images/catalogo/fregadero.png'
import CucumberMaki from '../assets/images/catalogo/fregadero.png'
import HungrySet from '../assets/images/catalogo/fregadero.png'
import NigiriOcean from '../assets/images/catalogo/fregadero.png'
import FoggyAlbion from '../assets/images/catalogo/fregadero.png'
import StanleyKubrick from '../assets/images/catalogo/fregadero.png'

export const allProductsData = [
  {
    id: 'cheese-pizza',
    ItemImg: CheesePizza,
    ItemName: 'Cheese Pizza',
    ItemIngredients: 'Pizza dough, pizza sauce, provolone cheese, mozzarella.',
    ItemPrice: (2).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },

  {
    id: 'veggie-pizza',
    ItemImg: VeggiePizza,
    ItemName: 'Veggie Pizza',
    ItemIngredients:
      'Pizza sauce, Onion, Capsicum, Pineapple, Ginger, Hotshot sauce, Coriander, Garlic sauce, Butter sauce, Cheese.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pepperoni-pizza',
    ItemImg: PepperoniPizza,
    ItemName: 'Pepperoni Pizza',
    ItemIngredients:
      'Pizza crust yeast, pepperoni, tomato paste, mozzarella cheese, sugar.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'meat-pizza',
    ItemImg: MeatPizza,
    ItemName: 'Meat Pizza',
    ItemIngredients:
      'Sausage, pizza dough, bacon, pizza sauce, red pepper flakes.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'margherita-pizza',
    ItemImg: MargheritaPizza,
    ItemName: 'Margherita Pizza',
    ItemIngredients:
      'Pizza dough, tomato sauce, fresh mozzarella, olive oil, basil leaves.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'bbq-chicken-pizza',
    ItemImg: BBQPizza,
    ItemName: 'BBQ Chicken Pizza',
    ItemIngredients:
      'Rotisserie chicken, pizza dough, bbq sauce, smoked cheddar cheese, mozzarella cheese.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'alla-pala-pizza',
    ItemImg: AllaPalla,
    ItemName: 'Pizza alla pala',
    ItemIngredients: 'Cheese, cherry tomatoes, arugula, olive oil, yeast.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'prosciutto-e-funghi-pizza',
    ItemImg: Prosciutto,
    ItemName: 'Prosciutto e Funghi Pizza',
    ItemIngredients:
      'Italian sausage meat, truffle oil, pizza dough, san marzano tomatoes, bocconcini cheese.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'detroit-style-pizza',
    ItemImg: DetroitStyle,
    ItemName: 'Detroit-Style Pizza',
    ItemIngredients:
      'Pizza dough, pizza sauce, brick cheese, rapid rise yeast, olive oil.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'garlic-fingers',
    ItemImg: GarlicFingers,
    ItemName: 'Garlic Fingers',
    ItemIngredients:
      'Bread flour, mozzarella cheese, butter, olive oil, instant yeast.',
    ItemPrice: (7).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'st-ouis-style-pizza',
    ItemImg: StLous,
    ItemName: 'St. Louis-Style Pizza',
    ItemIngredients:
      'Liquid smoke, swiss cheese, white cheddar cheese, light corn syrup, pizza sauce.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'rustica-pizza',
    ItemImg: Rustica,
    ItemName: 'Pizza Rustica',
    ItemIngredients: 'Prosciutto, pepperoni, mozzarella, crust, eggs.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pesto-genovese-pizza',
    ItemImg: Genovese,
    ItemName: 'Pizza pesto Genovese',
    ItemIngredients:
      'Pine nuts, baby potatoes, snow pea shoots, lemon, buffalo mozzarella.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'e-fichi-pizza',
    ItemImg: Efichi,
    ItemName: 'Pizza e fichi',
    ItemIngredients: 'Coconut flour, truffle oil, fig jam, cheese, prosciutto.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'apizza-pizza',
    ItemImg: Apizza,
    ItemName: 'Apizza',
    ItemIngredients:
      'Ricotta cheese, tomato sauce, fresh basil pesto, fresh mozzarella cheese, semolina flour.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'stuffed-pizza',
    ItemImg: StuffedPizza,
    ItemName: 'Stuffed Pizza',
    ItemIngredients:
      'Hot italian sausage, sweet italian sausage, pizza dough, red pepper flakes, olive oil.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pugliese-pizza',
    ItemImg: Pugliese,
    ItemName: 'Pugliese Pizza',
    ItemIngredients:
      'Sloppy joe, banana peppers, ground beef, pizza dough, ricotta cheese.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'tomato-pie',
    ItemImg: TomatoPie,
    ItemName: 'Tomato Pie',
    ItemIngredients:
      'Pizza dough, san marzano, red pepper flakes, pizza stone, olive oil.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fugazza',
    ItemImg: Fugaza,
    ItemName: 'Fugazza',
    ItemIngredients: 'Mozzarella cheese, sugar, dry yeast, all purpose flour.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fiori-di-zucca',
    ItemImg: DiZucca,
    ItemName: 'Pizza fiori di zucca',
    ItemIngredients:
      'Zucchini blossoms, pizza dough, buffalo mozzarella, olive oil.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fugazzeta',
    ItemImg: Fugazeta,
    ItemName: 'Fugazzeta',
    ItemIngredients:
      'Feta cheese, mozzarella cheese, parmesan cheese, olive oil, yeast.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'carbonara-pizza',
    ItemImg: Carbonara,
    ItemName: 'Pizza Carbonara',
    ItemIngredients:
      'Refrigerated pizza crust, monterey jack cheese, parmesan cheese, butter, chicken bouillon.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'grandma-pie',
    ItemImg: GranmaPie,
    ItemName: 'Grandma Pie',
    ItemIngredients:
      'Dark brown sugar, san marzano tomatoes, tomato paste, bread flour, red pepper flakes.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-al-padellino',
    ItemImg: Padellino,
    ItemName: 'Pizza al padellino',
    ItemIngredients:
      'Mozzarella cheese, prosciutto di parma, dough, baby arugula, black pepper',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'california-style-pizza',
    ItemImg: CaliforniaStyle,
    ItemName: 'California-Style Pizza',
    ItemIngredients:
      'Feta cheese, marinated artichoke hearts, pizza crust, red bell pepper, red onion.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-fritta',
    ItemImg: Fritta,
    ItemName: 'Pizza fritta',
    ItemIngredients:
      'Ricotta cheese, tomato sauce, tipo 00, neapolitan pizza base, mozzarella cheese.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pinsa-romana',
    ItemImg: Romana,
    ItemName: 'Pinsa romana',
    ItemIngredients:
      'Rice flour, olive oil, plain flour, sea salt, dried yeast.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'chicago-thin-crust-pizza',
    ItemImg: ThinCrust,
    ItemName: 'Chicago Thin Crust Pizza',
    ItemIngredients:
      'Sweet italian sausage, semolina flour, pizza sauce, giardiniera, sugar.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'greek-style-pizza',
    ItemImg: GreekStyle,
    ItemName: 'Greek-Style Pizza',
    ItemIngredients:
      'White cheddar, sauce, tomato paste, bread flour, red pepper flakes.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-romana',
    ItemImg: PizzaRomana,
    ItemName: 'Pizza Romana',
    ItemIngredients: 'Fresh mozzarella, bread flour, tomato, olive oil, yeast',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'extra-cheese-pizza',
    ItemImg: ExtraCheesePizza,
    ItemName: 'Extra Cheese Pizza',
    ItemIngredients: 'Homemade pizza sauce, fresh mozzarella cheese, mozzarella cheese, parmesan cheese, olive oil',
    ItemPrice: (5).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },

  {
    id: 'veggie-pizza-second',
    ItemImg: VeggiePizza,
    ItemName: 'Veggie Pizza',
    ItemIngredients:
      'Pizza sauce, Onion, Capsicum, Pineapple, Ginger, Hotshot sauce, Coriander, Garlic sauce, Butter sauce, Cheese.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pepperoni-pizza-second',
    ItemImg: PepperoniPizza,
    ItemName: 'Pepperoni Pizza',
    ItemIngredients:
      'Pizza crust yeast, pepperoni, tomato paste, mozzarella cheese, sugar.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'meat-pizza-second',
    ItemImg: MeatPizza,
    ItemName: 'Meat Pizza',
    ItemIngredients:
      'Sausage, pizza dough, bacon, pizza sauce, red pepper flakes.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'margherita-pizza-second',
    ItemImg: MargheritaPizza,
    ItemName: 'Margherita Pizza',
    ItemIngredients:
      'Pizza dough, tomato sauce, fresh mozzarella, olive oil, basil leaves.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'bbq-chicken-pizza-second',
    ItemImg: BBQPizza,
    ItemName: 'BBQ Chicken Pizza',
    ItemIngredients:
      'Rotisserie chicken, pizza dough, bbq sauce, smoked cheddar cheese, mozzarella cheese.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'alla-pala-pizza-second',
    ItemImg: AllaPalla,
    ItemName: 'Pizza alla pala',
    ItemIngredients: 'Cheese, cherry tomatoes, arugula, olive oil, yeast.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'prosciutto-e-funghi-pizza-second',
    ItemImg: Prosciutto,
    ItemName: 'Prosciutto e Funghi Pizza',
    ItemIngredients:
      'Italian sausage meat, truffle oil, pizza dough, san marzano tomatoes, bocconcini cheese.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'detroit-style-pizza-second',
    ItemImg: DetroitStyle,
    ItemName: 'Detroit-Style Pizza',
    ItemIngredients:
      'Pizza dough, pizza sauce, brick cheese, rapid rise yeast, olive oil.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'garlic-fingers-second',
    ItemImg: GarlicFingers,
    ItemName: 'Garlic Fingers',
    ItemIngredients:
      'Bread flour, mozzarella cheese, butter, olive oil, instant yeast.',
    ItemPrice: (7).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'st-ouis-style-pizza-second',
    ItemImg: StLous,
    ItemName: 'St. Louis-Style Pizza',
    ItemIngredients:
      'Liquid smoke, swiss cheese, white cheddar cheese, light corn syrup, pizza sauce.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'rustica-pizza-second',
    ItemImg: Rustica,
    ItemName: 'Pizza Rustica',
    ItemIngredients: 'Prosciutto, pepperoni, mozzarella, crust, eggs.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pesto-genovese-pizza-second',
    ItemImg: Genovese,
    ItemName: 'Pizza pesto Genovese',
    ItemIngredients:
      'Pine nuts, baby potatoes, snow pea shoots, lemon, buffalo mozzarella.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'e-fichi-pizza-second',
    ItemImg: Efichi,
    ItemName: 'Pizza e fichi',
    ItemIngredients: 'Coconut flour, truffle oil, fig jam, cheese, prosciutto.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'apizza-pizza-second',
    ItemImg: Apizza,
    ItemName: 'Apizza',
    ItemIngredients:
      'Ricotta cheese, tomato sauce, fresh basil pesto, fresh mozzarella cheese, semolina flour.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'stuffed-pizza-second',
    ItemImg: StuffedPizza,
    ItemName: 'Stuffed Pizza',
    ItemIngredients:
      'Hot italian sausage, sweet italian sausage, pizza dough, red pepper flakes, olive oil.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pugliese-pizza-second',
    ItemImg: Pugliese,
    ItemName: 'Pugliese Pizza',
    ItemIngredients:
      'Sloppy joe, banana peppers, ground beef, pizza dough, ricotta cheese.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'tomato-pie-second',
    ItemImg: TomatoPie,
    ItemName: 'Tomato Pie',
    ItemIngredients:
      'Pizza dough, san marzano, red pepper flakes, pizza stone, olive oil.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fugazza-second',
    ItemImg: Fugaza,
    ItemName: 'Fugazza',
    ItemIngredients: 'Mozzarella cheese, sugar, dry yeast, all purpose flour.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fiori-di-zucca-second',
    ItemImg: DiZucca,
    ItemName: 'Pizza fiori di zucca',
    ItemIngredients:
      'Zucchini blossoms, pizza dough, buffalo mozzarella, olive oil.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'fugazzeta-second',
    ItemImg: Fugazeta,
    ItemName: 'Fugazzeta',
    ItemIngredients:
      'Feta cheese, mozzarella cheese, parmesan cheese, olive oil, yeast.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'carbonara-pizza-second',
    ItemImg: Carbonara,
    ItemName: 'Pizza Carbonara',
    ItemIngredients:
      'Refrigerated pizza crust, monterey jack cheese, parmesan cheese, butter, chicken bouillon.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'grandma-pie-second',
    ItemImg: GranmaPie,
    ItemName: 'Grandma Pie',
    ItemIngredients:
      'Dark brown sugar, san marzano tomatoes, tomato paste, bread flour, red pepper flakes.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-al-padellino-second',
    ItemImg: Padellino,
    ItemName: 'Pizza al padellino',
    ItemIngredients:
      'Mozzarella cheese, prosciutto di parma, dough, baby arugula, black pepper',
    ItemPrice: (10).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'california-style-pizza-second',
    ItemImg: CaliforniaStyle,
    ItemName: 'California-Style Pizza',
    ItemIngredients:
      'Feta cheese, marinated artichoke hearts, pizza crust, red bell pepper, red onion.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-fritta-second',
    ItemImg: Fritta,
    ItemName: 'Pizza fritta',
    ItemIngredients:
      'Ricotta cheese, tomato sauce, tipo 00, neapolitan pizza base, mozzarella cheese.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pinsa-romana-second',
    ItemImg: Romana,
    ItemName: 'Pinsa romana',
    ItemIngredients:
      'Rice flour, olive oil, plain flour, sea salt, dried yeast.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'chicago-thin-crust-pizza-second',
    ItemImg: ThinCrust,
    ItemName: 'Chicago Thin Crust Pizza',
    ItemIngredients:
      'Sweet italian sausage, semolina flour, pizza sauce, giardiniera, sugar.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'greek-style-pizza-second',
    ItemImg: GreekStyle,
    ItemName: 'Greek-Style Pizza',
    ItemIngredients:
      'White cheddar, sauce, tomato paste, bread flour, red pepper flakes.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'pizza-romana-second',
    ItemImg: PizzaRomana,
    ItemName: 'Pizza Romana',
    ItemIngredients: 'Fresh mozzarella, bread flour, tomato, olive oil, yeast',
    ItemPrice: (11).toFixed(2),
    Category: 'Pizza',
    attributes: [
      {
        id: 'size',
        name: 'size',
        attributeOptions: [
          { id: 'small', value: 'small', slices: '6' },
          { id: 'medium', value: 'medium', slices: '8' },
          { id: 'large', value: 'large', slices: '10' },
          { id: 'x-large', value: 'x-large', slices: '12' },
        ],
      },
    ],
  },
  {
    id: 'chicken-alfredo',
    ItemImg: ChickenAlfredo,
    ItemName: 'Chicken Alfredo',
    ItemIngredients:
      'Skinless chicken breast, heavy cream, olive oil, parmigiano reggiano, black pepper.',
    ItemPrice: (2).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'pasta-alla-gricia',
    ItemImg: AllaGricia,
    ItemName: 'Pasta Alla Gricia',
    ItemIngredients:
      'Rigatoni pasta, pancetta, olive oil, pecorino romano, black pepper.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'sheet-pan-burrata-caprese-gnocchi',
    ItemImg: SheetPan,
    ItemName: 'Sheet-Pan Burrata Caprese Gnocchi',
    ItemIngredients:
      'Penne pasta, sour cream, rotisserie chicken, cherry tomatoes, curly kale.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'penne-alla-vodka',
    ItemImg: AllaVodka,
    ItemName: 'Penne Alla Vodka',
    ItemIngredients:
      'Tomato sauce, penne, heavy cream, red pepper flakes, butter.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'baked-spaghetti',
    ItemImg: BakedSpaghetti,
    ItemName: 'Baked Spaghetti',
    ItemIngredients:
      'Ground beef, spaghetti, olive oil, crushed tomatoes, basil.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'cacio-e-pepe-potato-gnocchi',
    ItemImg: PotatoGnocchi,
    ItemName: 'Cacio e Pepe Potato Gnocchi',
    ItemIngredients:
      'Green beans, potato gnocchi, green peas, pecorino romano cheese, black pepper.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'ultimate-baked-ziti',
    ItemImg: BakedZiti,
    ItemName: 'Ultimate Baked Ziti',
    ItemIngredients:
      'Bulk italian sausage, ricotta cheese, tomato sauce, red pepper flakes, mozzarella cheese.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'cacio-e-pepe',
    ItemImg: CacioPepe,
    ItemName: 'Cacio e Pepe',
    ItemIngredients: 'Spaghetti, parmesan, butter, black pepper.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'pasta-puttanesca',
    ItemImg: PastaPuttanesca,
    ItemName: 'Pasta Puttanesca',
    ItemIngredients:
      'Spaghetti, kalamata olives, red pepper flakes, capers, diced tomatoes.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'italian-pasta-salad',
    ItemImg: ItalianPastaSalad,
    ItemName: 'Italian Pasta Salad',
    ItemIngredients:
      'Bell peppers, fresh mozzarella pearls, honey, dijon mustard, rotini.',
    ItemPrice: (7).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lasagna-roll-ups',
    ItemImg: Lasagna,
    ItemName: 'Lasagna Roll Ups',
    ItemIngredients:
      'Ricotta cheese, lasagna noodles, homemade marinara sauce, frozen spinach, parmesan cheese.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'toasted-ravioli',
    ItemImg: ToastedRavioli,
    ItemName: 'Toasted Ravioli',
    ItemIngredients:
      'Frozen cheese ravioli, jar spaghetti sauce, bread crumbs, parmesan cheese, egg.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'orecchiette-with-broccoli-rabe',
    ItemImg: OrecchietteBroccoli,
    ItemName: 'Orecchiette with Broccoli Rabe',
    ItemIngredients:
      'Broccoli rabe, andouille sausage links, orecchiette, lemon, red pepper flakes.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lamb-ragu',
    ItemImg: LambRagu,
    ItemName: 'Lamb Ragù',
    ItemIngredients:
      'Lamb shoulder, gnocchi, red wine, heavy cream, tomato paste.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lasagna-bolognese',
    ItemImg: LasagnaBolognese,
    ItemName: 'Lasagna Bolognese',
    ItemIngredients:
      'Ground italian sausage, ground beef, ground pork, bolognese sauce, lasagna noodles.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lemon-ricotta-pasta',
    ItemImg: LemonRicotta,
    ItemName: 'Lemon Ricotta Pasta',
    ItemIngredients: 'Spaghetti, ricotta, lemon, red pepper flakes, parmesan.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'stuffed-rigatoni',
    ItemImg: StuffedRigatoni,
    ItemName: 'Stuffed Rigatoni',
    ItemIngredients:
      'Ground turkey, rigatoni pasta, red pepper, mozzarella, parmesan.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'five-cheese-ziti-al-forno',
    ItemImg: FiveCheese,
    ItemName: 'Five-Cheese Ziti Al Forno',
    ItemIngredients:
      'Alfredo sauce, ricotta cheese, ziti pasta, marinara sauce, fontina cheese.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'chicken-florentine-pasta',
    ItemImg: ChickenFlorentine,
    ItemName: 'Chicken Florentine Pasta',
    ItemIngredients:
      'Skinless chicken breasts, linguine pasta, chicken broth, butter, baby spinach.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'italian-mac-and-cheese',
    ItemImg: MacCheese,
    ItemName: 'Italian Mac and Cheese',
    ItemIngredients:
      'Italian sausage, pasta, cheese sauce, cream, baby spinach.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'chicken-alfredo-second',
    ItemImg: ChickenAlfredo,
    ItemName: 'Chicken Alfredo',
    ItemIngredients:
      'Skinless chicken breast, heavy cream, olive oil, parmigiano reggiano, black pepper.',
    ItemPrice: (2).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'pasta-alla-gricia-second',
    ItemImg: AllaGricia,
    ItemName: 'Pasta Alla Gricia',
    ItemIngredients:
      'Rigatoni pasta, pancetta, olive oil, pecorino romano, black pepper.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'sheet-pan-burrata-caprese-gnocchi-second',
    ItemImg: SheetPan,
    ItemName: 'Sheet-Pan Burrata Caprese Gnocchi',
    ItemIngredients:
      'Penne pasta, sour cream, rotisserie chicken, cherry tomatoes, curly kale.',
    ItemPrice: (5).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'penne-alla-vodka-second',
    ItemImg: AllaVodka,
    ItemName: 'Penne Alla Vodka',
    ItemIngredients:
      'Tomato sauce, penne, heavy cream, red pepper flakes, butter.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'baked-spaghetti-second',
    ItemImg: BakedSpaghetti,
    ItemName: 'Baked Spaghetti',
    ItemIngredients:
      'Ground beef, spaghetti, olive oil, crushed tomatoes, basil.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'cacio-e-pepe-potato-gnocchi-second',
    ItemImg: PotatoGnocchi,
    ItemName: 'Cacio e Pepe Potato Gnocchi',
    ItemIngredients:
      'Green beans, potato gnocchi, green peas, pecorino romano cheese, black pepper.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'ultimate-baked-ziti-second',
    ItemImg: BakedZiti,
    ItemName: 'Ultimate Baked Ziti',
    ItemIngredients:
      'Bulk italian sausage, ricotta cheese, tomato sauce, red pepper flakes, mozzarella cheese.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'cacio-e-pepe-second',
    ItemImg: CacioPepe,
    ItemName: 'Cacio e Pepe',
    ItemIngredients: 'Spaghetti, parmesan, butter, black pepper.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'pasta-puttanesca-second',
    ItemImg: PastaPuttanesca,
    ItemName: 'Pasta Puttanesca',
    ItemIngredients:
      'Spaghetti, kalamata olives, red pepper flakes, capers, diced tomatoes.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'italian-pasta-salad-second',
    ItemImg: ItalianPastaSalad,
    ItemName: 'Italian Pasta Salad',
    ItemIngredients:
      'Bell peppers, fresh mozzarella pearls, honey, dijon mustard, rotini.',
    ItemPrice: (7).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lasagna-roll-ups-second',
    ItemImg: Lasagna,
    ItemName: 'Lasagna Roll Ups',
    ItemIngredients:
      'Ricotta cheese, lasagna noodles, homemade marinara sauce, frozen spinach, parmesan cheese.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'toasted-ravioli-second',
    ItemImg: ToastedRavioli,
    ItemName: 'Toasted Ravioli',
    ItemIngredients:
      'Frozen cheese ravioli, jar spaghetti sauce, bread crumbs, parmesan cheese, egg.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'orecchiette-with-broccoli-rabe-second',
    ItemImg: OrecchietteBroccoli,
    ItemName: 'Orecchiette with Broccoli Rabe',
    ItemIngredients:
      'Broccoli rabe, andouille sausage links, orecchiette, lemon, red pepper flakes.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lamb-ragu-second',
    ItemImg: LambRagu,
    ItemName: 'Lamb Ragù',
    ItemIngredients:
      'Lamb shoulder, gnocchi, red wine, heavy cream, tomato paste.',
    ItemPrice: (12).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lasagna-bolognese-second',
    ItemImg: LasagnaBolognese,
    ItemName: 'Lasagna Bolognese',
    ItemIngredients:
      'Ground italian sausage, ground beef, ground pork, bolognese sauce, lasagna noodles.',
    ItemPrice: (11).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'lemon-ricotta-pasta-second',
    ItemImg: LemonRicotta,
    ItemName: 'Lemon Ricotta Pasta',
    ItemIngredients: 'Spaghetti, ricotta, lemon, red pepper flakes, parmesan.',
    ItemPrice: (13).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'stuffed-rigatoni-second',
    ItemImg: StuffedRigatoni,
    ItemName: 'Stuffed Rigatoni',
    ItemIngredients:
      'Ground turkey, rigatoni pasta, red pepper, mozzarella, parmesan.',
    ItemPrice: (9).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'five-cheese-ziti-al-forno-second',
    ItemImg: FiveCheese,
    ItemName: 'Five-Cheese Ziti Al Forno',
    ItemIngredients:
      'Alfredo sauce, ricotta cheese, ziti pasta, marinara sauce, fontina cheese.',
    ItemPrice: (15).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'chicken-florentine-pasta-second',
    ItemImg: ChickenFlorentine,
    ItemName: 'Chicken Florentine Pasta',
    ItemIngredients:
      'Skinless chicken breasts, linguine pasta, chicken broth, butter, baby spinach.',
    ItemPrice: (10).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'italian-mac-and-cheese-second',
    ItemImg: MacCheese,
    ItemName: 'Italian Mac and Cheese',
    ItemIngredients:
      'Italian sausage, pasta, cheese sauce, cream, baby spinach.',
    ItemPrice: (8).toFixed(2),
    Category: 'Pasta',
    attributes: [],
  },
  {
    id: 'salmon-poke',
    ItemImg: SalmonPoke,
    ItemName: 'Salmon Poke',
    ItemIngredients:
      'Salmon, Avocado, Rice, Cabbage, Carrots, Creen onions, Sesame.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },

  {
    id: 'veggie-poke',
    ItemImg: VeggiePoke,
    ItemName: 'Veggie Poke',
    ItemIngredients:
      'Rice, Avocado, Cabage, Carrot, Ginger, Green Onion, Sesam.',
    ItemPrice: (8).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'mini-salmon-set-22-pcs',
    ItemImg: MiniSalmon,
    ItemName: 'Mini Salmon Set - 22Pcs',
    ItemIngredients:
      'Double Salmon Roll,Californication, Salmon Nigiri - 2Pcs, Eel Nigiri - 2Pcs,Vulcan Gunkan - 2Pcs',
    ItemPrice: (35).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-bull-of-ombos-set-32-pcs',
    ItemImg: OmbosSeth,
    ItemName: 'The Bull of Ombos Set - 32 Pcs',
    ItemIngredients:
      'Double Salmon Roll, Sunny Philadelphia, Salmon Maki Raki, Cucumber Maki Raki.',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'veggie-set-32-pcs',
    ItemImg: VeggieSet,
    ItemName: 'Veggie Set 32Pcs',
    ItemIngredients:
      'Very Veggie Roll, Very Veggie Maki, Cucumber Maki Raki, Avocado Maki Raki.',
    ItemPrice: (16).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'friendly-set-40-pcs',
    ItemImg: FriendlySet,
    ItemName: 'Friendly Set 40Pcs',
    ItemIngredients:
      'Californication, Spicy Salmon Roll, Blissful Eel Roll, Sebastian the Crab Roll, Cucumber Maki Raki.',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'sunny-philadelphia-roll-8-pcs',
    ItemImg: SunnyPhiladelphia,
    ItemName: 'Sunny Philadelphia Roll 8Pcs',
    ItemIngredients: 'Salmon, cream cheese, avocado, rice, sesame, nori.',
    ItemPrice: (12).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'hidden-dragon-roll-8-pcs',
    ItemImg: HiddenDragon,
    ItemName: 'Hidden Dragon Roll 8Pcs',
    ItemIngredients:
      'Salmon, cream cheese, avocado, tobiko, rice, sesame, cucumber, nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'double-salmon-roll-8-pcs',
    ItemImg: DoubleSalmon,
    ItemName: 'Double Salmon Roll 8Pcs',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Sesame, Extra Salmon Slice, Cucumber, Rice, nori.',
    ItemPrice: (14).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-hottest-taisho',
    ItemImg: HottestTaisho,
    ItemName: 'The Hottest Taisho',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Cucumber, Rice - all comes with your chosen sauce.',
    ItemPrice: (12).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'burning-taisho',
    ItemImg: BurningTaisho,
    ItemName: 'Burning Taisho',
    ItemIngredients: 'Salmon, Avocado, Rice, Cucumber, Cream Cheese.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'blissful-eel-roll-8-pcs',
    ItemImg: BlissfulEel,
    ItemName: 'Blissful Eel Roll 8Pcs',
    ItemIngredients:
      'Eel kinda blissful, Cream Cheese, Avocado, Tobico, Sesame, Rice, Nori.',
    ItemPrice: (14).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'sebastian-the-crab-roll-8-pcs',
    ItemImg: Sebastian,
    ItemName: 'Sebastian the Crab Roll 8Pcs',
    ItemIngredients:
      'Crab with Unique Sauce, Cream Cheese, Avocado, Dried Tuna Flakes, Sesame, Cucumber Rice, Nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'double-salmon-roll-8-pcs-3',
    ItemImg: DoubleSalmon,
    ItemName: 'Double Salmon Roll 8Pcs',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Sesame, Extra Salmon Slice, Cucumber, Rice, Nori.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'clockwork-orange-roll-8-pcs',
    ItemImg: ClockworkOrange,
    ItemName: 'Clockwork Orange Roll 8Pcs',
    ItemIngredients: 'Salmon, orange, cream cheese, salmon extra slice, nori.',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'kiwi-roll-8-pcs',
    ItemImg: KiwiRoll,
    ItemName: 'Kiwi Roll 8Pcs',
    ItemIngredients:
      'Salmon, Kiwi, Carrot, Cream Cheese, Tobico, Cucumber, Rice, Nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-roll-of-siamese',
    ItemImg: Siamese,
    ItemName: 'The Roll of Siamese',
    ItemIngredients: 'Salmon, Cucumber, Carrot, Caviar, Rice.',
    ItemPrice: (15).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'salmon-maki-raki-8-pcs',
    ItemImg: SalmonMaki,
    ItemName: 'Salmon Maki Raki 8Pcs',
    ItemIngredients: 'Salmon, Cream Cheese, Rice, Nori.',
    ItemPrice: (9).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-roasted-salmon-1-pc',
    ItemImg: NigiriWithRoasted,
    ItemName: 'Nigiri with Roasted Salmon 1Pc',
    ItemIngredients: 'Roasted Salmon, Rice.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-salmon-and-orange-1-pc',
    ItemImg: NigiriWithSalmon,
    ItemName: 'Nigiri with Salmon and Orange 1Pc',
    ItemIngredients: 'Salmon, Orange, Rice.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-eel-1-pc',
    ItemImg: NigiriWithEel,
    ItemName: 'Nigiri with Eel 1Pc',
    ItemIngredients: 'Eel, rice.',
    ItemPrice: (6).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'vulcan-tobiko',
    ItemImg: VulcanTobiko,
    ItemName: 'Vulcan Tobiko',
    ItemIngredients: 'Delicious Gunkan with Tobiko and Rice.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'salmon-gunkan-with-cream-cheese',
    ItemImg: SalmonGunkan,
    ItemName: 'Salmon Gunkan with Cream Cheese',
    ItemIngredients: 'Perfect sushi rice, Nigiri prawns, Cream cheese, Wasabi.',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'very-vegetarian-maki-8-pcs',
    ItemImg: VeryVegetarian,
    ItemName: 'Very Vegetarian Maki 8Pcs',
    ItemIngredients: 'Avocado, Cream Cheese, Cucumber, Rice, Nori.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'avocado-maki-raki-8-pcs',
    ItemImg: SushiOne,
    ItemName: 'Avocado Maki Raki 8Pcs',
    ItemIngredients: 'Avocado, Cream Cheese, Rice, Nori.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'cucumber-maki-raki-8-pcs',
    ItemImg: CucumberMaki,
    ItemName: 'Cucumber Maki Raki 8Pcs',
    ItemIngredients: 'Cucumber, Sesame, Rice, Nori.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'hungry-set-64-pcs',
    ItemImg: HungrySet,
    ItemName: 'Hungry Set 64Pcs',
    ItemIngredients:
      'Californication,  Sunny Philadelphia Roll, Spicy Salmon Roll, Blissful Eel Roll, Sebastian the Crab Roll, Very Vegetarian Maki Raki, Cucumber Maki Raki,  Avocado Maki Raki.',
    ItemPrice: (50).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-ocean-14-pcs',
    ItemImg: NigiriOcean,
    ItemName: 'Nigiri Ocean - 14 Pcs',
    ItemIngredients:
      '14 Different Nigiris: Salmon Nigiri - 2Pcs, Salmon Nigiri with Orange - 2Pcs, Eel Nigiri - 2Pcs, Tuna Nigiri - 2Pcs, Fried Tuna Nigiri - 2Pcs, Fried Salmon Nigiri - 2Pcs, Kiwi Nigiri - 2Pcs',
    ItemPrice: (40).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'foggy-albion-26-pcs',
    ItemImg: FoggyAlbion,
    ItemName: 'Foggy Albion - 26Pcs',
    ItemIngredients:
      'Sunny Philadelphia, Sebastian the Crab, Cucumber Maki Raki, Salmon Gunkan with Cream Cheese - 2Pcs',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'stanley-kubrick-24-pcs',
    ItemImg: StanleyKubrick,
    ItemName: 'Stanley Kubrick - 24 Pcs',
    ItemIngredients:
      'Clockwork Orange Roll, Spicy Salmon Roll, Very Vegetarian Maki',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'salmon-poke-second',
    ItemImg: SalmonPoke,
    ItemName: 'Salmon Poke',
    ItemIngredients:
      'Salmon, Avocado, Rice, Cabbage, Carrots, Creen onions, Sesame.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },

  {
    id: 'veggie-poke-second',
    ItemImg: VeggiePoke,
    ItemName: 'Veggie Poke',
    ItemIngredients:
      'Rice, Avocado, Cabage, Carrot, Ginger, Green Onion, Sesam.',
    ItemPrice: (8).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'mini-salmon-set-22-pcs-second',
    ItemImg: MiniSalmon,
    ItemName: 'Mini Salmon Set - 22Pcs',
    ItemIngredients:
      'Double Salmon Roll,Californication, Salmon Nigiri - 2Pcs, Eel Nigiri - 2Pcs,Vulcan Gunkan - 2Pcs',
    ItemPrice: (35).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-bull-of-ombos-set-32-pcs-second',
    ItemImg: OmbosSeth,
    ItemName: 'The Bull of Ombos Set - 32 Pcs',
    ItemIngredients:
      'Double Salmon Roll, Sunny Philadelphia, Salmon Maki Raki, Cucumber Maki Raki.',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'veggie-set-32-pcs-second',
    ItemImg: VeggieSet,
    ItemName: 'Veggie Set 32Pcs',
    ItemIngredients:
      'Very Veggie Roll, Very Veggie Maki, Cucumber Maki Raki, Avocado Maki Raki.',
    ItemPrice: (16).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'friendly-set-40-pcs-second',
    ItemImg: FriendlySet,
    ItemName: 'Friendly Set 40Pcs',
    ItemIngredients:
      'Californication, Spicy Salmon Roll, Blissful Eel Roll, Sebastian the Crab Roll, Cucumber Maki Raki.',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'sunny-philadelphia-roll-8-pcs-second',
    ItemImg: SunnyPhiladelphia,
    ItemName: 'Sunny Philadelphia Roll 8Pcs',
    ItemIngredients: 'Salmon, cream cheese, avocado, rice, sesame, nori.',
    ItemPrice: (12).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'hidden-dragon-roll-8-pcs-second',
    ItemImg: HiddenDragon,
    ItemName: 'Hidden Dragon Roll 8Pcs',
    ItemIngredients:
      'Salmon, cream cheese, avocado, tobiko, rice, sesame, cucumber, nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'double-salmon-roll-8-pcs-second',
    ItemImg: DoubleSalmon,
    ItemName: 'Double Salmon Roll 8Pcs',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Sesame, Extra Salmon Slice, Cucumber, Rice, nori.',
    ItemPrice: (14).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-hottest-taisho-second',
    ItemImg: HottestTaisho,
    ItemName: 'The Hottest Taisho',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Cucumber, Rice - all comes with your chosen sauce.',
    ItemPrice: (12).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'burning-taisho-second',
    ItemImg: BurningTaisho,
    ItemName: 'Burning Taisho',
    ItemIngredients: 'Salmon, Avocado, Rice, Cucumber, Cream Cheese.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'blissful-eel-roll-8-pcs-second',
    ItemImg: BlissfulEel,
    ItemName: 'Blissful Eel Roll 8Pcs',
    ItemIngredients:
      'Eel kinda blissful, Cream Cheese, Avocado, Tobico, Sesame, Rice, Nori.',
    ItemPrice: (14).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'sebastian-the-crab-roll-8-pcs-second',
    ItemImg: Sebastian,
    ItemName: 'Sebastian the Crab Roll 8Pcs',
    ItemIngredients:
      'Crab with Unique Sauce, Cream Cheese, Avocado, Dried Tuna Flakes, Sesame, Cucumber Rice, Nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'double-salmon-roll-8-pcs-sec',
    ItemImg: DoubleSalmon,
    ItemName: 'Double Salmon Roll 8Pcs',
    ItemIngredients:
      'Salmon, Cream Cheese, Avocado, Sesame, Extra Salmon Slice, Cucumber, Rice, Nori.',
    ItemPrice: (10).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'clockwork-orange-roll-8-pcs-second',
    ItemImg: ClockworkOrange,
    ItemName: 'Clockwork Orange Roll 8Pcs',
    ItemIngredients: 'Salmon, orange, cream cheese, salmon extra slice, nori.',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'kiwi-roll-8-pcs-second',
    ItemImg: KiwiRoll,
    ItemName: 'Kiwi Roll 8Pcs',
    ItemIngredients:
      'Salmon, Kiwi, Carrot, Cream Cheese, Tobico, Cucumber, Rice, Nori.',
    ItemPrice: (13).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'the-roll-of-siamese-second',
    ItemImg: Siamese,
    ItemName: 'The Roll of Siamese',
    ItemIngredients: 'Salmon, Cucumber, Carrot, Caviar, Rice.',
    ItemPrice: (15).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'salmon-maki-raki-8-pcs-second',
    ItemImg: SalmonMaki,
    ItemName: 'Salmon Maki Raki 8Pcs',
    ItemIngredients: 'Salmon, Cream Cheese, Rice, Nori.',
    ItemPrice: (9).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-roasted-salmon-1-pc-second',
    ItemImg: NigiriWithRoasted,
    ItemName: 'Nigiri with Roasted Salmon 1Pc',
    ItemIngredients: 'Roasted Salmon, Rice.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-salmon-and-orange-1-pc-second',
    ItemImg: NigiriWithSalmon,
    ItemName: 'Nigiri with Salmon and Orange 1Pc',
    ItemIngredients: 'Salmon, Orange, Rice.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-eel-1-pc-second',
    ItemImg: NigiriWithEel,
    ItemName: 'Nigiri with Eel 1Pc',
    ItemIngredients: 'Eel, rice.',
    ItemPrice: (6).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'vulcan-tobiko-second',
    ItemImg: VulcanTobiko,
    ItemName: 'Vulcan Tobiko',
    ItemIngredients: 'Delicious Gunkan with Tobiko and Rice.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'salmon-gunkan-with-cream-cheese-second',
    ItemImg: SalmonGunkan,
    ItemName: 'Salmon Gunkan with Cream Cheese',
    ItemIngredients: 'Perfect sushi rice, Nigiri prawns, Cream cheese, Wasabi.',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'very-vegetarian-maki-8-pcs-second',
    ItemImg: VeryVegetarian,
    ItemName: 'Very Vegetarian Maki 8Pcs',
    ItemIngredients: 'Avocado, Cream Cheese, Cucumber, Rice, Nori.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'avocado-maki-raki-8-pcs-second',
    ItemImg: SushiOne,
    ItemName: 'Avocado Maki Raki 8Pcs',
    ItemIngredients: 'Avocado, Cream Cheese, Rice, Nori.',
    ItemPrice: (5).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'cucumber-maki-raki-8-pcs-second',
    ItemImg: CucumberMaki,
    ItemName: 'Cucumber Maki Raki 8Pcs',
    ItemIngredients: 'Cucumber, Sesame, Rice, Nori.',
    ItemPrice: (4).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'hungry-set-64-pcs-second',
    ItemImg: HungrySet,
    ItemName: 'Hungry Set 64Pcs',
    ItemIngredients:
      'Californication,  Sunny Philadelphia Roll, Spicy Salmon Roll, Blissful Eel Roll, Sebastian the Crab Roll, Very Vegetarian Maki Raki, Cucumber Maki Raki,  Avocado Maki Raki.',
    ItemPrice: (50).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-ocean-14-pcs-second',
    ItemImg: NigiriOcean,
    ItemName: 'Nigiri Ocean - 14 Pcs',
    ItemIngredients:
      '14 Different Nigiris: Salmon Nigiri - 2Pcs, Salmon Nigiri with Orange - 2Pcs, Eel Nigiri - 2Pcs, Tuna Nigiri - 2Pcs, Fried Tuna Nigiri - 2Pcs, Fried Salmon Nigiri - 2Pcs, Kiwi Nigiri - 2Pcs',
    ItemPrice: (40).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'foggy-albion-26-pcs-second',
    ItemImg: FoggyAlbion,
    ItemName: 'Foggy Albion - 26Pcs',
    ItemIngredients:
      'Sunny Philadelphia, Sebastian the Crab, Cucumber Maki Raki, Salmon Gunkan with Cream Cheese - 2Pcs',
    ItemPrice: (30).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'stanley-kubrick-24-pcs-second',
    ItemImg: StanleyKubrick,
    ItemName: 'Stanley Kubrick - 24 Pcs',
    ItemIngredients:
      'Clockwork Orange Roll, Spicy Salmon Roll, Very Vegetarian Maki',
    ItemPrice: (11).toFixed(2),
    Category: 'Sushi',
    attributes: [],
  },
  {
    id: 'nigiri-with-salmon-and-orange-1-pc-sale',
    ItemImg: NigiriWithSalmon,
    ItemName: 'Nigiri with Salmon and Orange 1Pc',
    ItemIngredients: 'Salmon, Orange, Rice.',
    ItemPriceBefore: (4).toFixed(2),
    ItemPrice: (3).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'pepperoni-pizza-sale',
    ItemImg: PepperoniPizza,
    ItemName: 'Pepperoni Pizza Medium',
    ItemIngredients:
      'Pizza crust yeast, pepperoni, tomato paste, mozzarella cheese, sugar.',
    ItemPriceBefore: (5).toFixed(2),
    ItemPrice: (3).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'meat-pizza-sale',
    ItemImg: MeatPizza,
    ItemName: 'Meat Pizza Medium',
    ItemIngredients:
      'Sausage, pizza dough, bacon, pizza sauce, red pepper flakes.',
    ItemPriceBefore: (10).toFixed(2),
    ItemPrice: (8).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'margherita-pizza-sale',
    ItemImg: MargheritaPizza,
    ItemName: 'Margherita Pizza Small',
    ItemIngredients:
      'Pizza dough, tomato sauce, fresh mozzarella, olive oil, basil leaves.',
    ItemPriceBefore: (8).toFixed(2),
    ItemPrice: (7).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'veggie-pizza-sale',
    ItemImg: VeggiePizza,
    ItemName: 'Veggie Pizza Large',
    ItemIngredients:
      'Pizza sauce, Onion, Capsicum, Pineapple, Ginger, Hotshot sauce, Coriander, Garlic sauce, Butter sauce, Cheese.',
    ItemPriceBefore: (9).toFixed(2),
    ItemPrice: (7).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'sebastian-the-crab-roll-8-pcs-sale',
    ItemImg: Sebastian,
    ItemName: 'Sebastian the Crab Roll 8Pcs',
    ItemIngredients:
      'Crab with Unique Sauce, Cream Cheese, Avocado, Dried Tuna Flakes, Sesame, Cucumber Rice, Nori.',
    ItemPriceBefore: (13).toFixed(2),
    ItemPrice: (10).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'lamb-ragu-sale',
    ItemImg: LambRagu,
    ItemName: 'Lamb Ragù',
    ItemIngredients:
      'Lamb shoulder, gnocchi, red wine, heavy cream, tomato paste.',
    ItemPriceBefore: (12).toFixed(2),
    ItemPrice: (10).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'cheese-pizza-sale',
    ItemImg: CheesePizza,
    ItemName: 'Cheese Pizza Large',
    ItemIngredients: 'Pizza dough, pizza sauce, provolone cheese, mozzarella.',
    ItemPriceBefore: (2).toFixed(2),
    ItemPrice: (1).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'cacio-e-pepe-potato-gnocchi-sale',
    ItemImg: PotatoGnocchi,
    ItemName: 'Cacio e Pepe Potato Gnocchi',
    ItemIngredients:
      'Green beans, potato gnocchi, green peas, pecorino romano cheese, black pepper.',
    ItemPriceBefore: (10).toFixed(2),
    ItemPrice: (9).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },

  {
    id: 'nigiri-with-salmon-and-orange-1-pc-sale-second',
    ItemImg: NigiriWithSalmon,
    ItemName: 'Nigiri with Salmon and Orange 1Pc',
    ItemIngredients: 'Salmon, Orange, Rice.',
    ItemPriceBefore: (4).toFixed(2),
    ItemPrice: (3).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'pepperoni-pizza-sale-second',
    ItemImg: PepperoniPizza,
    ItemName: 'Pepperoni Pizza Medium',
    ItemIngredients:
      'Pizza crust yeast, pepperoni, tomato paste, mozzarella cheese, sugar.',
    ItemPriceBefore: (5).toFixed(2),
    ItemPrice: (3).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'meat-pizza-sale-second',
    ItemImg: MeatPizza,
    ItemName: 'Meat Pizza Large',
    ItemIngredients:
      'Sausage, pizza dough, bacon, pizza sauce, red pepper flakes.',
    ItemPriceBefore: (10).toFixed(2),
    ItemPrice: (8).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'cacio-e-pepe-potato-gnocchi-sale-second',
    ItemImg: PotatoGnocchi,
    ItemName: 'Cacio e Pepe Potato Gnocchi',
    ItemIngredients:
      'Green beans, potato gnocchi, green peas, pecorino romano cheese, black pepper.',
    ItemPriceBefore: (10).toFixed(2),
    ItemPrice: (8).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'margherita-pizza-sale-second',
    ItemImg: MargheritaPizza,
    ItemName: 'Margherita Pizza Medium',
    ItemIngredients:
      'Pizza dough, tomato sauce, fresh mozzarella, olive oil, basil leaves.',
    ItemPriceBefore: (8).toFixed(2),
    ItemPrice: (7).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'veggie-pizza-sale-second',
    ItemImg: VeggiePizza,
    ItemName: 'Veggie Pizza Large',
    ItemIngredients:
      'Pizza sauce, Onion, Capsicum, Pineapple, Ginger, Hotshot sauce, Coriander, Garlic sauce, Butter sauce, Cheese.',
    ItemPriceBefore: (9).toFixed(2),
    ItemPrice: (8).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'sebastian-the-crab-roll-8-pcs-sale-second',
    ItemImg: Sebastian,
    ItemName: 'Sebastian the Crab Roll 8Pcs',
    ItemIngredients:
      'Crab with Unique Sauce, Cream Cheese, Avocado, Dried Tuna Flakes, Sesame, Cucumber Rice, Nori.',
    ItemPriceBefore: (13).toFixed(2),
    ItemPrice: (11).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'lamb-ragu-sale-second',
    ItemImg: LambRagu,
    ItemName: 'Lamb Ragù',
    ItemIngredients:
      'Lamb shoulder, gnocchi, red wine, heavy cream, tomato paste.',
    ItemPriceBefore: (12).toFixed(2),
    ItemPrice: (11).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  },
  {
    id: 'cheese-pizza-sale-second',
    ItemImg: CheesePizza,
    ItemName: 'Cheese Pizza Medium',
    ItemIngredients: 'Pizza dough, pizza sauce, provolone cheese, mozzarella.',
    ItemPriceBefore: (2).toFixed(2),
    ItemPrice: (1).toFixed(2),
    Category: 'Sale',
    sale: true,
    attributes: [],
  }
]
